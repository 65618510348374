
<template>
  <div class="dragMain">
    <draggable class="dragA" :v-model="arr1">
      <transition-group>
        <div class="item" v-for="item in arr1"  :group="groupA" :key="item.id">
          {{ item.name }}
        </div>
      </transition-group>
    </draggable>
    <draggable class="dragB"  :group="groupB" :v-model="arr2">
      <transition-group>
        <div class="item" v-for="item in arr2" :key="item.id">
          {{ item.name }}
        </div>
      </transition-group>
    </draggable>

  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  components:{
    draggable
  },
  data(){
    return{
      listA:[],
      listB:[],
      groupA: {
        name: "itxst",
        pull: true,  //可以拖出
        put: true,   //可以拖入
      },
      groupB: {
        name: "itxst",
        pull: true,
        put: true,
      },
      arr1: [
              { id: 1, name: 'www.itxst.com' },
              { id: 2, name: 'www.jd.com' },
              { id: 3, name: 'www.baidu.com' },
              { id: 4, name: 'www.taobao.com' }
          ],
                    arr2: [
                        { id: 1, name: 'www.google.com' },
                        { id: 2, name: 'www.msn.com' },
                        { id: 3, name: 'www.ebay.com' },
                        { id: 4, name: 'www.yahoo.com' }
                    ]
    }
  }

}
</script>

<style lang="scss">

.dragMain{
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  position: absolute;
  .dragA{
    height: 100%;
    width: 20%;
    border: 1px solid rgb(49, 125, 169);
    position: relative;
  }
  .dragB{
    height: 100%;
    width: 20%;
    border: 1px solid rgb(39, 87, 122);
    position: relative;

  }
  /* background: red; */
  
}

</style>